import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';

import { useAppStore } from '@store';
import { SERVER_HOST } from '@configuration/config';

import styles from './RulesList.module.scss';

const RulesList = (props: any) => {
  const {
    directorateStore: { loadDirectorates, directorates },
  } = useAppStore();

  // get Directorate data
  useEffect(() => {
    loadDirectorates();
  }, []);
  const currnetDirectorate = directorates[0]; // HARDCODE - only one directorate (no directorate id)

  // get OOPT data
  const { orderDataStore: { oopt } } = useAppStore();
  const { intl } = props;
  const currntOOPT = oopt;

  return (
    <ul className={styles['RulesList']}>
      <li>
        {/* Информационные материалы о посещении Прибайкальского национального парка туристами, экскурсантами */}
        <a
          href={`${SERVER_HOST}api/getFile?lang=ru&id=${currntOOPT?.docParkRules}`}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({ id: 'touristData.rulesOfStayLink' })}
        </a>
      </li>

      <li>
        {/* Правила пользования системой */}
        <a
          href={`${SERVER_HOST}api/getFile?lang=ru&id=${currnetDirectorate?.docSystemRules}`}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({ id: 'touristData.termsOfUseLink' })}
        </a>
      </li>

      <li>
        {/* Обработка персональных данных */}
        <a
          href={`${SERVER_HOST}api/getFile?lang=ru&id=${currnetDirectorate?.docPersonalData}`}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({ id: 'touristData.termsOfPersonalDataLink' })}
        </a>
      </li>
    </ul>
  );
}

export default injectIntl(RulesList);